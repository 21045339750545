import { createTheme, responsiveFontSizes } from '@mui/material';

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#0288D1',
      light: '#B3E5FC',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#607D8B',
    },
  }
}));

export default theme;
