import type { FC, ReactNode } from 'react';

import Graphql from './graphql';
import Membership from './membership';

const Wrapped: FC<{ children: ReactNode }> = ({ children }) => (
  <Graphql><Membership>{children}</Membership></Graphql>
);

export default Wrapped;
