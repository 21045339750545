import type { FC, ReactNode } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja';

const Provider : FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      {children}
    </LocalizationProvider>
  );
}

export default Provider
