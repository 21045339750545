import type {
  FC,
  ReactNode,
} from 'react'

import dynamic from 'next/dynamic'

type NoSSRProps = {
  children: ReactNode;
}
const NonSSRWrapper : FC<NoSSRProps> = ({ children }) => <>{children}</>

export default dynamic(() => Promise.resolve(NonSSRWrapper), {
  ssr: false,
})
