import type { FC, ReactNode } from 'react';

import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { type User, IdTokenResult, getIdTokenResult } from 'firebase/auth';
import {
  collection,
  where,
  query,
  documentId,
  onSnapshot,
  type Firestore,
} from 'firebase/firestore';
import { SignJWT, decodeJwt } from 'jose';
import { useSigninCheck, useFirestore } from 'reactfire';
import {
  makeOperation,
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider,
} from 'urql';

import FirebaseFirestoreProvider from './firebase/firestore';
 import FirebaseStorageProvider from './firebase/storage';

const baseUrl = process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL;
if (baseUrl === undefined)
  throw new Error('GRAPHQL_BASE_URL must not be undefined.');
const url = `${baseUrl}/v1/graphql`;

const getToken = async (accessToken: string) : Promise<string> => {
  if (process.env.NODE_ENV === 'production') return accessToken;

  if (typeof process.env.NEXT_PUBLIC_GRAPHQL_JWT_SECRET !== 'string')
    throw new Error('GRAPHQL_JWT_SECRET must be string');
  // will remove on build
  const decoded = decodeJwt(accessToken);
  const token = await new SignJWT(decoded)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(new TextEncoder().encode(process.env.NEXT_PUBLIC_GRAPHQL_JWT_SECRET));

  return token;
};

const waitSetClaims = async (db: Firestore, id: string): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    const usersQuery = query(
      collection(db, 'users'),
      where(documentId(), '==', id)
    );
    const unsubscribe = onSnapshot(
      usersQuery,
      (snap) => {
        const [user] = snap.docs;
        if (user === undefined) return;
        unsubscribe();
        resolve();
      },
      reject
    );
  });
};

const getIdToken = async (user: User | null, firestore: Firestore) => {
  if (user == null) return null;

  // login
  const idTokenResult0 = await getIdTokenResult(user);
  if (idTokenResult0.claims?.['https://hasura.io/jwt/claims'])
    return idTokenResult0;

  // signup and reload
  const idTokenResult1 = await getIdTokenResult(user, true);
  if (idTokenResult1.claims?.['https://hasura.io/jwt/claims'])
    return idTokenResult1;

  // just signup
  await waitSetClaims(firestore, user.uid);
  const idTokenResult2 = await getIdTokenResult(user, true);
  return idTokenResult2;
}

const authConfig = (
  user: User | null,
  firestore: Firestore,
): AuthConfig<{ idTokenResult: IdTokenResult | null, token?: string }> => ({
  /*
  didAuthError: ({ error }) => {
    return error.graphQLErrors.some(
      e => e.response.status === 401,
    );
  },
  */
  getAuth: async () => {
    const idTokenResult = await getIdToken(user, firestore)
    if (idTokenResult?.token === undefined) return { idTokenResult }
    const token = await getToken(idTokenResult.token)
    return { idTokenResult, token }
  },
  willAuthError: ({ authState }) => {
    if (authState == null) return true;
    const { idTokenResult, token } = authState
    if (idTokenResult == null) return true;
    if (!idTokenResult.claims?.['https://hasura.io/jwt/claims']) return true;

    if (idTokenResult.expirationTime) {
      const expirationDate = new Date(idTokenResult.expirationTime);
      return expirationDate < new Date();
    }

    if (idTokenResult.token === undefined) return true;
    
    return Boolean(token)
  },
  addAuthToOperation: ({ authState, operation }) => {
    if (!authState) return operation;

    const { token } = authState
    if (!token) return operation;

    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    const authorization = `Bearer ${token}`;

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          authorization,
        },
      },
    });
  },
});

const Wrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const firestore = useFirestore();
  const { status, data: signInCheckResult } = useSigninCheck();
  if (status === 'loading') return <></>
  
  const { user } = signInCheckResult
  const client = createClient({
    url,
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange(authConfig(user, firestore)),
      fetchExchange,
    ],
  });
  return <Provider value={client}>{children}</Provider>;
};

const Wrapped: FC<{ children: ReactNode }> = ({ children }) => (
  <FirebaseFirestoreProvider>
    <FirebaseStorageProvider>
      <Wrapper>{children}</Wrapper>
    </FirebaseStorageProvider>
  </FirebaseFirestoreProvider>
);

export default Wrapped;
