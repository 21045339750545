import {
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
  type FC,
  type ReactNode,
} from "react";

import { type AuthInternal } from "@firebase/auth/internal";
import {
  getAuth,
  connectAuthEmulator,
  getIdTokenResult,
  onAuthStateChanged,
} from "firebase/auth";
import { useRouter } from "next/router";
import { AuthProvider, useFirebaseApp, useUser } from "reactfire";

import AppProvider from "./app";

import { type UuidString, assertBrandedString } from "~/generated/graphql";

export type Role = "staff" | "user" | "anonymous";
export type UserId = UuidString | null;
export type GraphqlAuth = { role: "staff" | "user"; userId: UuidString; }
  | { role: "anonymous"; userId: null; };
export const AuthContext = createContext<GraphqlAuth>({
  role: "anonymous",
  userId: null,
});
export const useGraphqlAuth = () => useContext(AuthContext);

type Claims = {
  "https://hasura.io/jwt/claims": {
    "x-hasura-default-role": string;
    "x-hasura-user-id": UuidString;
  };
};

const PATH_NAMES = [
  "/",
  "/password-reset",
  "/signin",
  "/signup",
  "/events/[eventId]",
]

const assertRecord: (obj: unknown) => asserts obj is Record<string, never> = (
  obj
) => {
  if (obj === null) throw new Error("obj must not be null.");
  if (typeof obj !== "object") throw new Error("arg must be object");
};

const assertClaims: (a: unknown) => asserts a is Claims = (a) => {
  if (a == null) throw new Error("claims must not be null.");
  assertRecord(a);

  const c = a["https://hasura.io/jwt/claims"];

  if (c == null)
    throw new Error("'https://hasura.io/jwt/claims' must not be null.");
  assertRecord(c);

  const r = c["x-hasura-default-role"];

  if (typeof r !== "string")
    throw new Error("'x-hasura-default-role' must be string.");

  const i = c["x-hasura-user-id"];

  assertBrandedString<UuidString>(i);
};

const GraphqlAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { data: user } = useUser();
  const [graphqlAuth, setGraphqlAuth] = useState<GraphqlAuth>(
    { role: "anonymous", userId: null },
  );
  useEffect(() => {
    if (user === null) {
      setGraphqlAuth({ role: "anonymous", userId: null });
      return;
    }
    getIdTokenResult(user)
      .then((idTokenResult) => {
        try {
          const { claims } = idTokenResult;
          assertClaims(claims);
          const hasura = claims["https://hasura.io/jwt/claims"];
 
          if (hasura === undefined) {
            setGraphqlAuth({ role: "anonymous", userId: null });
            return
          }
          const userId = hasura["x-hasura-user-id"];
          const r = hasura["x-hasura-default-role"];
          if (r === "staff") setGraphqlAuth({ role: "staff", userId });
          else if (r === "user") setGraphqlAuth({ role: "user", userId });
          else setGraphqlAuth({ role: "anonymous", userId: null });
        } catch (e) {
          console.warn(e);
          return;
        }
      })
      .catch((e) => {
        throw e;
      });
  }, [user]);

  return (
    <AuthContext.Provider value={graphqlAuth}>
      {children}
    </AuthContext.Provider>
  );
};

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const app = useFirebaseApp();
  const auth = useMemo(() => {
    const $auth = getAuth(app);
    if (process.env.NODE_ENV === "production") return $auth;

    if (typeof process.env.NEXT_PUBLIC_AUTH_EMULATOR === "string") {
      // NOTE: 下記がないと auth/emulator-config-failed というエラーが発生する
      // 。https://stackoverflow.com/a/73605308 を参考にした。
      ($auth as AuthInternal)._canInitEmulator = true;
      connectAuthEmulator($auth, process.env.NEXT_PUBLIC_AUTH_EMULATOR);
    }
    return $auth;
  }, [app]);

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(auth, async (user) => {
      if (PATH_NAMES.includes(router.pathname)) return;

      if (user !== null) return;
      await router.push("/");
    });
    return () => {
      authStateChanged();
    };
  }, [auth, router]);

  return (
    <AuthProvider sdk={auth}>
      <GraphqlAuthProvider>{children}</GraphqlAuthProvider>
    </AuthProvider>
  );
};

const Wrapped: FC<{ children: React.ReactNode }> = ({ children }) => (
  <AppProvider>
    <Provider>{children}</Provider>
  </AppProvider>
);

export default Wrapped;
