import '@/styles/globals.css';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';

import type { NextPageWithLayout } from '@/types';
import type { AppProps } from 'next/app';

import NoSSR from '@/components/nossr'
import theme from '@/configs/theme';
import ClientProvider from '@/contexts/client';
import DatepickerProvider from '@/contexts/datepicker';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => (
  <ClientProvider>
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Yona Search</title>
    </Head>
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NoSSR>
        <DatepickerProvider>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            maxSnack={3}
          >
          {
            Component.getLayout !== undefined
              ? Component.getLayout(<Component {...pageProps} />)
              : (<><Component {...pageProps} /></>)
          }
          </SnackbarProvider>
        </DatepickerProvider>
      </NoSSR>
    </ThemeProvider>
  </ClientProvider>
);

export default App;
