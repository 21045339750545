import type { FC, ReactNode } from "react";
import { createContext, useContext } from "react";

import Error from "next/error";
import router from "next/router";
import { useAuth } from "reactfire";
import { gql } from "urql";

import {
  useGraphqlAuth,
  type Role,
  type GraphqlAuth,
} from "@/contexts/client/firebase/auth";
import {
  type UuidString,
  assertBrandedString,
  useFetchMembershipsByUserIdQuery,
} from "~/generated/graphql";

export const Query = gql`
  query FetchMembershipsByUserId($userId: uuid!) {
    memberships(where: { userId: { _eq: $userId } }, limit: 1) {
      groupId
    }
  }
`;

export type GroupId = UuidString | null;
export type Membership = { groupId: GroupId; role: Role };
export const MembershipContext = createContext<Membership>({
  role: "anonymous",
  groupId: null,
});
export const useMembership = () => useContext(MembershipContext);

const Inner: FC<{ children: ReactNode; graphqlAuth: GraphqlAuth }> = ({
  children,
  graphqlAuth,
}) => {
  const auth = useAuth();
  const { role, userId } = graphqlAuth;
  assertBrandedString<UuidString>(userId);
  const [{ data, fetching, error }] = useFetchMembershipsByUserIdQuery({
    variables: { userId: graphqlAuth.userId },
  });
  if (fetching)
    return (
      <MembershipContext.Provider value={{ role, groupId: null }}>
        {children}
      </MembershipContext.Provider>
    );
  if (error || data === undefined) return <Error statusCode={500} />;
  const [membership] = data.memberships;
  if (membership === undefined) {
    auth.signOut();
    router.push({
      pathname: "/",
      query: { message: "グループに所属していないので利用できません" },
    });
    return null;
  }
  const { groupId } = membership;
  return (
    <MembershipContext.Provider value={{ role, groupId }}>
      {children}
    </MembershipContext.Provider>
  );
};

const Wrapped: FC<{ children: ReactNode }> = ({ children }) => {
  const graphqlAuth = useGraphqlAuth();
  if (["staff", "anonymous"].includes(graphqlAuth.role))
    return (
      <MembershipContext.Provider
        value={{ role: graphqlAuth.role, groupId: null }}
      >
        {children}
      </MembershipContext.Provider>
    );
  return <Inner graphqlAuth={graphqlAuth}>{children}</Inner>;
};

export default Wrapped;
