import type { FC, ReactNode } from 'react';

import {
  getStorage,
  connectStorageEmulator,
} from 'firebase/storage';
import { StorageProvider, useFirebaseApp } from 'reactfire';


const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const app = useFirebaseApp();
  const storage = getStorage(app);

  if (process.env.NODE_ENV !== 'production') {
    if (typeof process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST === 'string'
      && typeof process.env.NEXT_PUBLIC_STORAGE_EMULATOR_PORT === 'string') {
      connectStorageEmulator(
        storage,
        process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST,
        Number(process.env.NEXT_PUBLIC_STORAGE_EMULATOR_PORT),
      )
    } 
  } 

  return <StorageProvider sdk={storage}>{children}</StorageProvider>;
};

export default Provider;
